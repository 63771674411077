import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { MatGridListModule } from '@angular/material/grid-list'

const COMPONENTS = []
const MODULES = [
  MatDialogModule,
  MatGridListModule,
]
const SYS_MODULES = [CommonModule, FormsModule]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...SYS_MODULES, ...MODULES],
  exports: [...SYS_MODULES, ...MODULES, ...COMPONENTS],
})
export class SharedModule {}
