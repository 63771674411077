<div class="professors-cv">
  <div class="title-wrapper">
    <span class="title">Συγχαρητήρια! Συμπληρώσατε σωστά την κατηγορία.</span>
    <span class="close-button" (click)="close() ">X</span>
  </div>
  <div class="cv-wrapper">
    <div class="cv-container"  *ngFor="let professor of data.professors">
      <div class="image" [style.background-image]="professor.image"></div>
      <h1 class="title">{{professor.name}}</h1>
      <div class="cv">{{professor.cv}}</div>
    </div>
  </div>
</div>
