<div class="game-wrapper">

  <div class="professors-container">
    <mat-grid-list
      cols="3"
      rowHeight="140px"
      cdkDropList
      id="professors"
      class="professors-list"
      [cdkDropListData]="professors"
      [cdkDropListConnectedTo]="FIELD_IDS"
      (cdkDropListDropped)="drop($event)"
      (cdkDropListEntered)="activateDrop()"
      (cdkDropListExited)="deactivateDrop()">
      <mat-grid-tile
        colspan="1"
        rowspan="1"
        class="professor-image"
        [style.background-image]="professor.image"
        *ngFor="let professor of professors"
        [id]="professor.id"
        (cdkDragReleased)="proximityDrop($event)"
        cdkDrag>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div class="scientific-fields-container">
    <mat-grid-list
      #pandemic
      class="scientific-field field-pandemic"
      [ngClass]="fieldsStatus.pandemic"
      cols="3"
      rowHeight="140px">
      <div *ngIf="fieldsLength.pandemic == pandemicSlots.length && pandemicScore!=pandemicSlots.length" class="score">
        <span>ΣΚΟΡ: {{pandemicScore}}/{{pandemicSlots.length}}</span>
      </div>
      <span class="label"
            [ngClass]="fieldsStatus.pandemic">
        ΠΑΝΔΗΜΙΑ
      </span>
      <mat-grid-tile
        *ngFor="let slot of pandemicSlots; let index = index;"
        id="pandemic-{{index}}"
        class="scientific-field-placeholder"
        colspan="1"
        rowspan="1"
        cdkDropList
        [ngClass]="fieldsStatus.pandemic"
        [style.mix-blend-mode]="getBlendMode(pandemicSlots[index])"
        [cdkDropListData]="pandemicSlots[index]"
        [cdkDropListConnectedTo]="FIELD_IDS_AND_PROFESSORS_ID"
        [cdkDropListEnterPredicate]="onlyOne"
        (cdkDropListDropped)="drop($event)"
        (cdkDropListEntered)="activateDrop()"
        (cdkDropListExited)="deactivateDrop()">
        <div
          *ngFor="let professor of pandemicSlots[index]"
          [id]="professor.id"
          class="professor-image"
          cdkDrag
          [style.background-image]="professor.image"
          [cdkDragDisabled]="fieldsStatus.pandemic === 'inactive'"
          (cdkDragReleased)="proximityDrop($event)"
        >
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list
      cols="3"
      rowHeight="140px"
      class="scientific-field field-space"
      #space
      [ngClass]="fieldsStatus.space"
      >
      <div *ngIf="fieldsLength.space == spaceSlots.length && spaceScore!=spaceSlots.length" class="score">
        <span>ΣΚΟΡ: {{spaceScore}}/{{spaceSlots.length}}</span>
      </div>
      <span
        class="label"
        [ngClass]="fieldsStatus.space">
        ΔΙΑΣΤΗΜΑ
      </span>
      <mat-grid-tile
        *ngFor="let slot of spaceSlots; let index = index;"
        id="space-{{index}}"
        class="scientific-field-placeholder"
        colspan="1"
        rowspan="1"
        cdkDropList
        [ngClass]="fieldsStatus.space"
        [style.mix-blend-mode]="getBlendMode(spaceSlots[index])"
        [cdkDropListData]="spaceSlots[index]"
        [cdkDropListConnectedTo]="FIELD_IDS_AND_PROFESSORS_ID"
        [cdkDropListEnterPredicate]="onlyOne"
        (cdkDropListDropped)="drop($event)"
        (cdkDropListEntered)="activateDrop()"
        (cdkDropListExited)="deactivateDrop()">
        <div
          *ngFor="let professor of spaceSlots[index]"
          [id]="professor.id"
          class="professor-image"
          cdkDrag
          [style.background-image]="professor.image"
          [cdkDragDisabled]="fieldsStatus.space === 'inactive'"
          (cdkDragReleased)="proximityDrop($event)"
        >
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list
      #climate
      class="scientific-field field-climate"
      cols="3"
      rowHeight="140px"
      [ngClass]="fieldsStatus.climate"
     >
      <div *ngIf="fieldsLength.climate == climateSlots.length && climateScore!=climateSlots.length" class="score">
        <span>ΣΚΟΡ: {{climateScore}}/{{climateSlots.length}}</span>
      </div>
      <span
        class="label"
        [ngClass]="fieldsStatus.climate">
        ΚΛΙΜΑΤΙΚΗ ΑΛΛΑΓΗ
      </span>
      <mat-grid-tile
        *ngFor="let slot of climateSlots; let index = index;"
        id="climate-{{index}}"
        class="scientific-field-placeholder"
        colspan="1"
        rowspan="1"
        cdkDropList
        [ngClass]="fieldsStatus.climate"
        [style.mix-blend-mode]="getBlendMode(climateSlots[index])"
        [cdkDropListData]="climateSlots[index]"
        [cdkDropListConnectedTo]="FIELD_IDS_AND_PROFESSORS_ID"
        [cdkDropListEnterPredicate]="onlyOne"
        (cdkDropListDropped)="drop($event)"
        (cdkDropListEntered)="activateDrop()"
        (cdkDropListExited)="deactivateDrop()">
        <div
          *ngFor="let professor of climateSlots[index]"
          class="professor-image"
          cdkDrag
          [style.background-image]="professor.image"
          [cdkDragDisabled]="fieldsStatus.climate === 'inactive'"
          [id]="professor.id"
          (cdkDragReleased)="proximityDrop($event)"
        >
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
<canvas *ngIf="isGameOver" #overlay>
</canvas>

<div [class.visible]="isGameOver" class="overlay">
  <span [class.active]="isGameOver">Συγχαρητήρια!!</span>
</div>
